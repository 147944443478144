<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>Payment History</b-card-title>
            </b-card-header>
            <b-overlay :show="show" rounded="sm" variant="transparent">
                <b-table :fields="fields" :items="items" responsive>
                    <template #cell(status)="data">
                        <b-badge
                            :variant="statusVariant(data.value)"
                            v-if="data.value"
                        >
                            {{
                                data.value.replace(/^./, (str) =>
                                    str.toUpperCase()
                                )
                            }}
                        </b-badge>
                    </template>
                    <template #cell(price)="data">
                        {{ data.item.total }}
                        {{ data.item.currency.toUpperCase() }}
                    </template>
                    <template #cell(payment_method)="data">
                        <a :href="data.item.payment_url" target="_blank">{{
                            data.value
                        }}</a>
                    </template>
                </b-table>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-number
                    last-number
                    align="center"
                    class="mt-1"
                    @input="handlePageClick"
                    ><template #prev-text>
                        <feather-icon size="18" icon="ChevronLeftIcon" />
                    </template>
                    <template #next-text>
                        <feather-icon size="18" icon="ChevronRightIcon" />
                    </template>
                </b-pagination>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
import {
    BTable,
    BBadge,
    BCard,
    BCardHeader,
    BCardTitle,
    BPagination,
    BOverlay,
} from "bootstrap-vue";
export default {
    components: {
        BTable,
        BBadge,
        BCard,
        BCardHeader,
        BCardTitle,
        BPagination,
        BOverlay,
    },
    created() {
        this.fetchData();
    },
    computed: {
        statusVariant() {
            const statusColor = {
                success: "light-primary",
                pending: "light-warning",
                completed: "light-success",
                confirmed: "light-success",
                resolved: "light-info",
                failed: "light-danger",
                delayed: "light-danger",
            };

            return (status) => statusColor[status];
        },
    },
    data() {
        return {
            show: false,
            currentPage: 1,
            perPage: 0,
            rows: 0,
            fields: [
                "id",
                "plan",
                "price",
                "payment_method",
                "status",
                "created_at",
            ],
            items: [],
        };
    },
    methods: {
        handlePageClick(pageNumber) {
            this.currentPage = pageNumber;
            this.fetchData();
        },
        fetchData() {
            this.show = true;
            this.$store
                .dispatch("historyPayment", this.currentPage)
                .then((res) => {
                    this.items = res.data.data;
                    this.perPage = res.data.meta.per_page;
                    this.rows = res.data.meta.total;
                    this.currentPage = res.data.meta.current_page;
                    this.items = res.data.data;
                    this.show = false;
                })
                .catch((err) => console.log(err));
        },
    },
};
</script>

<style></style>
