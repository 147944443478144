<template>
    <div>
        <b-row class="content-header">
            <!-- Content Left -->
            <b-col class="content-header-left mb-2" cols="12" md="9">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2 class="float-left mb-0">Profile</h2>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-tabs
            vertical
            content-class="col-12 col-md-9 mt-1 mt-md-0"
            pills
            nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left"
        >
            <b-tab active>
                <template #title>
                    <feather-icon icon="KeyIcon" size="18" class="mr-50" />
                    <span class="font-weight-bold">Status</span>
                </template>

                <status />
            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon icon="UserIcon" size="18" class="mr-50" />
                    <span class="font-weight-bold">Information</span>
                </template>

                <information />
            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon icon="ClockIcon" size="18" class="mr-50" />
                    <span class="font-weight-bold">Signed in logs</span>
                </template>
                <signed-logs />
            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon
                        icon="DollarSignIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">Payment History</span>
                </template>

                <payment-history />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { BTabs, BTab, BCol, BRow } from "bootstrap-vue";
import Information from "./Information.vue";
import PaymentHistory from "./PaymentHistory.vue";
import Status from "./Status.vue";
import SignedLogs from "./SignedLogs.vue";

export default {
    components: {
        BTabs,
        BTab,
        BCol,
        BRow,
        Information,
        PaymentHistory,
        Status,
        SignedLogs,
    },
    data() {
        return {
            show: false,
            checked: false,
        };
    },
};
</script>
