<template>
    <div>
        <b-card title="Information">
            <validation-observer ref="profileForm" #default="{ invalid }">
                <!-- form -->
                <b-form class="mt-2" @submit.prevent="onSubmit" v-if="show">
                    <b-row>
                        <b-col sm="6">
                            <b-form-group label="Name" label-for="name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Name"
                                    rules="required"
                                >
                                    <b-form-input
                                        placeholder="Name"
                                        name="name"
                                        v-model="getName"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group
                                label="E-mail"
                                label-for="account-e-mail"
                            >
                                <b-form-input
                                    name="email"
                                    placeholder="Email"
                                    v-model="email"
                                    readonly
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <!-- old password -->
                        <b-col md="6">
                            <b-form-group
                                label="Current Password"
                                label-for="current_password"
                                ><validation-provider
                                    #default="{ errors }"
                                    name="Current Password"
                                    rules="required|min:6"
                                    ><b-form-input
                                        id="current_password"
                                        v-model="current_password"
                                        name="current_password"
                                        type="password"
                                        placeholder="Current Password"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!--/ old password -->
                    </b-row>
                    <b-row>
                        <!-- new password -->
                        <b-col md="6">
                            <b-form-group
                                label-for="account-new-password"
                                label="New Password"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="new_password"
                                    rules="min:6"
                                >
                                    <b-form-input
                                        id="account-new-password"
                                        v-model="new_password"
                                        name="new-password"
                                        type="password"
                                        placeholder="New Password"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!--/ new password -->

                        <!-- retype password -->
                        <b-col md="6">
                            <b-form-group
                                label-for="account-retype-new-password"
                                label="Confirm New Password"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Confirm New Password"
                                    rules="confirmed:new_password"
                                >
                                    <b-form-input
                                        id="account-retype-new-password"
                                        v-model="retype_new_password"
                                        name="retype-password"
                                        type="password"
                                        placeholder="Confirm New Password"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!--/ retype password -->
                        <b-col cols="12">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mt-2 mr-1"
                                :disabled="invalid"
                                type="submit"
                            >
                                Save
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>
    </div>
</template>

<script>
import {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
    components: {
        BButton,
        BForm,
        BImg,
        BFormFile,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BAlert,
        BCard,
        BCardText,
        BMedia,
        BMediaAside,
        BMediaBody,
        BLink,
        BBadge,

        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    computed: {
        getName: {
            get() {
                this.name = this.$store.getters.getUser.name;
                return this.$store.getters.getUser.name;
            },
            set(val) {
                this.name = val;
            },
        },
        email() {
            return this.$store.getters.getUser.email;
        },
    },
    data() {
        return {
            show: true,
            name: "",
            current_password: "",
            new_password: "",
            retype_new_password: "",
        };
    },
    methods: {
        resetForm() {
            this.current_password = "";
            this.new_password = "";
            this.retype_new_password = "";
            // Trick to reset/clear native browser form validation state
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        onSubmit() {
            let data = {
                name: this.name,
                current_password: this.current_password,
                new_password: this.new_password,
                new_password_confirmation: this.retype_new_password,
            };
            this.$store
                .dispatch("updateProfile", data)
                .then((res) => {
                    if (res.data.success) {
                        this.$store.dispatch("fetchUser");
                        this.resetForm();
                        this.$bvToast.toast(res.data.message, {
                            title: "Message",
                            variant: "primary",
                            toaster: "b-toaster-bottom-right",
                            solid: true,
                        });
                    } else {
                        this.$bvToast.toast(res.data.message, {
                            title: "Message",
                            variant: "danger",
                            toaster: "b-toaster-bottom-right",
                            solid: true,
                        });
                    }
                })
                .catch((err) => {
                    this.$refs.profileForm.setErrors(err.response.data.errors);
                });
        },
    },
};
</script>
