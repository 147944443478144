<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>Signed in logs</b-card-title>
            </b-card-header>
            <b-overlay :show="show" rounded="sm" variant="transparent">
                <b-table :fields="fields" :items="items" responsive>
                    <template #cell(#)><div class="count"></div></template>
                    <template #cell(ip)="data">
                        {{ data.item.ip_address }}
                    </template>
                    <template #cell(country)="data">
                        <div v-if="data.item.country_code !== null">
                            <flag :iso="data.item.country_code" />
                            {{ toCountryName(data.item.country_code) }}
                        </div>
                    </template>
                </b-table>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-number
                    last-number
                    align="center"
                    class="mt-1"
                    @input="handlePageClick"
                    ><template #prev-text>
                        <feather-icon size="18" icon="ChevronLeftIcon" />
                    </template>
                    <template #next-text>
                        <feather-icon size="18" icon="ChevronRightIcon" />
                    </template>
                </b-pagination>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
import {
    BTable,
    BBadge,
    BCard,
    BCardHeader,
    BCardTitle,
    BPagination,
    BOverlay,
} from "bootstrap-vue";
export default {
    components: {
        BTable,
        BBadge,
        BCard,
        BCardHeader,
        BCardTitle,
        BPagination,
        BOverlay,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            show: false,
            currentPage: 1,
            perPage: 0,
            rows: 0,
            fields: ["#", "ip", "user_agent", "country", "login_at"],
            items: [],
        };
    },
    computed: {
        toCountryName() {
            return (countryCode) =>
                new Intl.DisplayNames(["en"], { type: "region" }).of(
                    countryCode
                );
        },
    },
    methods: {
        handlePageClick(pageNumber) {
            this.currentPage = pageNumber;
            this.fetchData();
        },
        fetchData() {
            this.show = true;
            this.$store
                .dispatch("historyLogin", this.currentPage)
                .then((res) => {
                    this.items = res.data.data;
                    this.perPage = res.data.meta.per_page;
                    this.rows = res.data.meta.total;
                    this.currentPage = res.data.meta.current_page;
                    this.items = res.data.data;
                    this.show = false;
                })
                .catch((err) => console.log(err));
        },
    },
};
</script>

<style>
table {
    counter-reset: section;
}
.count:before {
    counter-increment: section;
    content: counter(section);
}
</style>
