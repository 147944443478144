<template>
    <div>
        <b-card title="Status">
            <b-row>
                <b-col sm="6">
                    <p>
                        Type:
                        <strong class="text-primary">{{ statusUser }}</strong>
                    </p>
                </b-col>
                <b-col sm="6">
                    <p>
                        Expiration:
                        <strong class="text-primary">{{
                            expiredPremium
                        }}</strong>
                    </p>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BCard,
    },
    computed: {
        statusUser() {
            const statusText = {
                true: "Premium",
                false: "Free",
            };
            return statusText[this.$store.getters.getUser.has_premium];
        },
        expiredPremium() {
            return this.$store.getters.getUser.expired_at
                ? this.$store.getters.getUser.expired_at
                : "Lifetime Free";
        },
    },
};
</script>

<style></style>
